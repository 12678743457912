import '../../node_modules/bootstrap/js/dist/button';
import '../../node_modules/bootstrap/js/dist/carousel';
import '../../node_modules/bootstrap/js/dist/tooltip';

document.addEventListener('DOMContentLoaded', function() {
  const sidebar = document.getElementById('sidebar');
  const sidebarCollapse = document.getElementById('sidebarCollapse');
  const sidebarDismiss = document.getElementById('dismiss');
  const overlayDiv = document.getElementsByClassName('overlay')[0];

  [sidebarDismiss, overlayDiv].forEach(function(element) {
    element.addEventListener('click', function() {
      sidebar.classList.remove('show');
      overlayDiv.classList.remove('active');
      sidebarCollapse.setAttribute('aria-expanded', false);
    });
  });

  sidebarCollapse.addEventListener('click', function() {
    overlayDiv.classList.add('active');
    sidebar.classList.add('show');
    sidebarCollapse.setAttribute('aria-expanded', true);
  });

  const forms = document.forms;

  if (forms.length == 0) {
    return
  } else {
    // get the form object
    const contactForm = forms.contactForm;
    // disable native browser validation
    contactForm.setAttribute('novalidate', '');
    // add csrf_token to formData if one exists
    if (contactForm.hasAttribute('csrf_token')) {
      FormData.append('csrf_token', contactForm.getAttribute('value'));
    }
    // get all inputs
    const name = contactForm.contactName;
    const email = contactForm.contactEmail;
    const message = contactForm.contactMessage;
    const formInputs = [name, email, message];
    // validate inputs on form submit
    contactForm.addEventListener('submit', function(e) {
      formInputs.forEach(function(input) {
        input.setAttribute('required', '');

        if (!input.checkValidity()) {
          e.preventDefault();

          if (input.getAttribute('name') === 'name') {
            const regexName = /^[A-Za-z]+$/;

            if (!regexName.test(input.value) && input.value.length < 6) {
              input.classList.add('is-invalid');
            }

          } else if (input.getAttribute('name') === 'email') {
            const regexEmail = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

            if (!regexEmail.test(input.value)) {
              input.classList.add('is-invalid');
            }

          } else if (input.getAttribute('name') === 'message') {
            if (input.value === '' || input.value.length < 10) {
              input.classList.add('is-invalid');
            }
          }
        }
      });

      if (contactForm.checkValidity()) {
        formInputs.forEach(function(input) {
          input.classList.add('is-valid');
        });
      }
    });

    contactForm.addEventListener('reset', function() {
      formInputs.forEach(function(input) {
        if (input.classList.contains('is-invalid')) {
          input.value = '';
          input.classList.remove('is-invalid');
        } else {
          input.value = '';
          input.classList.remove('is-valid');
        }
      })
    });
  }
});
